const PrivacyPolicy = () => (
    <>
        <h2>Privacy Policy</h2>
        <h3>1. Introduction</h3>
        <p>
            1.1. Welcome to qualifyingmiles.com! This policy is a part of our Terms of Service. By visiting the Website
            or using the Apps, you consent to our use of your personal information in the ways set out in this policy
            and in our Terms of Service. We take our responsibilities under applicable privacy laws and regulations
            ("Privacy Laws") seriously. We are committed to respecting the privacy rights and concerns of all Users
            of our Website, Apps, and Services.
        </p>
        <p>
            1.2. We recognize the importance of the personal data you have entrusted to us and believe that it is
            our responsibility to properly manage, protect and process your personal data. This Privacy Policy
            ("Privacy Policy" or "Policy") is designed to assist you in understanding how we collect, use, disclose,
            and/or process the personal data you have provided to us and/or we possess about you, whether now or in
            the future, as well as to assist you in making an informed decision before providing us with any of your
            personal data. If you have any questions regarding this information or our privacy practices, please
            contact us.
        </p>
        <p>
            1.3. By using the Services, registering for an account with us, visiting our Website, or accessing the
            Services, you acknowledge and agree that you accept the practices, requirements, and/or policies
            outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or
            processing your personal data as described herein. We may revise this Privacy Policy but the most
            current version will always be at this link.
        </p>
        <p>
            1.4. Failure to comply with this Policy or our Terms of Service may result in termination of your account
            without notice.
        </p>

        <h3>2. Information Collected or Received</h3>
        <p>
            2.1. To use our platform to transact, you will need to provide us with a valid email address and phone
            number. Please note that for security and performance reasons, such content may need to be accessed by
            internal personnel from time to time.
        </p>

        <p>
            2.2. We use technologies such as cookies, clear gifs, log files, and flash cookies for several purposes,
            including to help understand how you interact with our site and services, in order to provide a better
            experience. The information we collect may include, without limitation, your Internet Protocol (IP)
            address, computer/mobile device operating system and browser type, type of mobile device, the
            characteristics of the mobile device, the address of a referring website (if any), and the pages you
            visit on our website and mobile applications and the times of visit.
        </p>

        <p>
            2.3. We automatically receive and record information (for example; an email address or IP address,
            cookies, and data) about a person who is not yet registered in connection with certain features.
        </p>

        <p>
            2.4. When you connect to us or register an account using external third-party applications or services,
            such as Facebook, using an application programming interface (API), you will be granting us permission
            to receive information from these third-party applications. You can also choose to share some of your
            activity to certain social media networks which are connected to your account.
        </p>

        <h3>3. Uses and Sharing</h3>

        <p>
            3.1. We respect your privacy. We will not sell or disclose your name, email address or other personal
            information to third parties without your consent, except as specified in our Privacy Policy.
        </p>

        <p>
            3.2. We generally use the information to provide and improve our services and products, for billing and
            payments, for identification and authentication, for targeted online and offline marketing, to contact
            members or interested parties, and for general research and aggregate reporting.
        </p>

        <p>
            3.3. We may use your information to contact you. Primarily, these messages are delivered by email or by
            push notifications, and every account is required to keep a valid email address and phone number on file
            to receive messages. We may contact you by telephone to provide support or for transaction-related
            purposes.
        </p>

        <p>
            3.4. Some messages from us are service-related and required for users. Examples of service-related
            messages include an email address confirmation/welcome email when you register your account,
            notification of a proposed exchange of employees, and correspondence with the site. You may not opt-out
            of receiving service-related messages from us, unless you close your account.
        </p>

        <p>
            3.5. We may release your personal information to a third party in the following circumstances: to
            protect, establish, or exercise our legal rights or defend against legal claims or that such disclosure
            is necessary to comply with the law, such as investigations into illegal activities.
        </p>

        <p>
            3.6. We may share demographic information with business partners, but it will always be aggregated and
            anonymized, so personally-identifiable information is not revealed.
        </p>

        <h3>4. Community</h3>
        <p>
            4.1. We are both a Website and a community. We may offer several features that allow users to
            communicate in public or in private. Please use common sense and good judgment when posting or sharing
            your personal information with others. We reserve the right to remove any content which in our sole
            judgment is not appropriate. Be aware that any personal information you submit there can be read,
            collected, or used by others, read by an administrator, or could be used to send you unsolicited
            messages. You are responsible for the personal information you choose to post.
        </p>

        <h3>5. Security</h3>
        <p>
            5.1. The security of your personal information is important to us. Your account information is protected
            by a password. It is important that you protect against unauthorized access to your account and
            information by choosing your password carefully, and keeping your password and computer secure, such as
            by signing out after using our services.
        </p>

        <p>
            5.2. We follow generally accepted industry standards to protect the personal information submitted to
            us, both during transmission and once we receive it.
        </p>

        <p>
            5.3. Unfortunately, no method of transmission over the Internet, or method of electronic storage, is
            100% secure. Therefore, while we strive to protect your personal information, we can't guarantee its
            absolute security.
        </p>

        <h3>6. Data Retention</h3>
        <p>
            6.1. We will retain your information only for as long as is necessary to provide you services. If you no
            longer want us to use your information to provide you services, you may close your account. We will
            retain and use your information to the extent necessary to comply with our legal obligations, resolve
            disputes, and enforce our agreements.
        </p>

        <h3>7. Changing Data</h3>
        <p>
            7.1. As a user, you can access, correct, change, and delete certain personal information associated with
            your account by visiting your account settings.
        </p>
    </>
);

export default PrivacyPolicy;
