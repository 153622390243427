import { useCallback } from "react";
import FFPPicker from "./FFPPicker";
import FFPInformation from "../interfaces/FFPInformation";
import FlightBuilder from "./FlightBuilder";
import { useSearchParams } from "react-router-dom";

function getTravelYear(param: string | null): '2023' | '2024' | '2025' {
    const year = parseInt(param ?? '') || new Date().getFullYear();
    if (year === 2023)
        return '2023';
    if (year === 2024)
        return '2024';
    return '2025';
}

const Calculator = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const ffpInformation: FFPInformation = {
        program: searchParams.get('p') === 'AFKL' ? 'AFKL' : 'DL',
        travelYear: getTravelYear(searchParams.get('y')),
        eliteStatus: (searchParams.get('s') || ''),
        creditCards: searchParams.getAll('cc') || [],
        endorsements: searchParams.getAll('e') || [],
    };

    const airline = searchParams.get('a') || '';
    const fareClass = searchParams.get('f') || '';

    const defaultFareClass = (!airline && !fareClass) ? null : { airline, fareClass };

    const setFfpInformation = useCallback((ffp: FFPInformation) => {
        setSearchParams(Object.fromEntries(Object.entries({
            q: searchParams.get('q') || '',
            p: ffp.program || '',
            s: ffp.eliteStatus || '',
            cc: ffp.creditCards,
            e: ffp.endorsements,
            y: ffp.travelYear,
            a: searchParams.get('a') || '',
            f: searchParams.get('f') || '',
            'sr': searchParams.get('sr') || '',
            'sm': searchParams.get('sm') || '',
            'sd': searchParams.get('sd') || '',
            'sx': searchParams.get('sx') || '',
            'su': searchParams.get('su') || '',
        }).filter(([_, value]) => !!value)));
    }, [searchParams, setSearchParams]);

    const setDefaultFareClass = useCallback((df: { airline: string, fareClass: string } | null) => {
        setSearchParams(Object.fromEntries(Object.entries({
            q: searchParams.get('q') || '',
            p: searchParams.get('p') || '',
            s: searchParams.get('s') || '',
            cc: searchParams.getAll('cc') || [],
            e: searchParams.getAll('e') || [],
            y: searchParams.get('y') || '',
            a: df?.airline || '',
            f: df?.fareClass || '',
            'sr': searchParams.get('sr') || '',
            'sm': searchParams.get('sm') || '',
            'sd': searchParams.get('sd') || '',
            'sx': searchParams.get('sx') || '',
            'su': searchParams.get('su') || '',
        }).filter(([_, value]) => !!value)));
    }, [searchParams, setSearchParams]);

    return (
        <div className="calculator">
            <h1 className='h2'>Estimate Frequent Flyer Earnings</h1>
            <p>Calculate how many miles you will earn and how close it will get you to elite status.</p>

            <FlightBuilder ffpInformation={ffpInformation} defaultFareClass={defaultFareClass} />

            <hr className="my-4" />

            <FFPPicker
                ffpInformation={ffpInformation}
                defaultFareClass={defaultFareClass}
                onFfpInformationChange={setFfpInformation}
                onDefaultFareClassChange={setDefaultFareClass}
            />
        </div>
    );
}

export default Calculator;