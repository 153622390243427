import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react";

interface IControlledTextArea extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

const ControlledTextArea = ({ value, onChange, ...rest }: IControlledTextArea) => {
    const [cursor, setCursor] = useState<number>(0);
    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        ref.current?.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setCursor(e.target.selectionStart);
        onChange && onChange(e);
    }, [setCursor, onChange]);

    return (
        <textarea ref={ref} value={value} onChange={handleChange} {...rest} />
    );
};

export default ControlledTextArea;
