import { Col, Form, Row } from "react-bootstrap";
import FFPInformation from "../interfaces/FFPInformation";
import React, { useCallback } from "react";

interface Properties {
    ffpInformation: FFPInformation;
    defaultFareClass: {
        airline: string;
        fareClass: string;
    } | null;
    onFfpInformationChange: (ffpInformation: FFPInformation) => void;
    onDefaultFareClassChange: (fareClass: { airline: string; fareClass: string; } | null) => void;
}

const FFPPicker = (props: Properties) => {
    const { ffpInformation: ffp, onFfpInformationChange, onDefaultFareClassChange } = props;

    const onProgramChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
        onFfpInformationChange({
            ...ffp,
            program: e.target.value as 'DL' | 'AFKL',
        }),
        [ffp, onFfpInformationChange]);

    const onMedallionStatusChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
        onFfpInformationChange({
            ...ffp,
            eliteStatus: e.target.value,
        }),
        [ffp, onFfpInformationChange]);

    const onSkyClubChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        onFfpInformationChange({
            ...ffp,
            endorsements: ffp.endorsements
                .filter(x => x !== "sky-club-individual" && x !== "sky-club-executive")
                .concat(e.target.checked ? [e.target.value] : []),
        }),
        [ffp, onFfpInformationChange]);

    const onCreditCardChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        onFfpInformationChange({
            ...ffp,
            creditCards: ffp.creditCards
                .filter(x => x !== e.target.value)
                .concat(e.target.checked ? [e.target.value] : []),
        }),
        [ffp, onFfpInformationChange]);

    const onTravelYearChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) =>
        onFfpInformationChange({
            ...ffp,
            travelYear: e.target.value as '2023' | '2024' | '2025',
        }),
        [ffp, onFfpInformationChange]);

    const onDefaultFareClassValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        onDefaultFareClassChange({
            airline: (e.target.name === "fare-class-airline" ? e.target.value?.toUpperCase() : props.defaultFareClass?.airline) || '',
            fareClass: (e.target.name === "fare-class" ? e.target.value?.toUpperCase() : props.defaultFareClass?.fareClass) || '',
        }),
        [onDefaultFareClassChange, props.defaultFareClass?.airline, props.defaultFareClass?.fareClass]);

    const unlimitedSkyClubVisits =
        ffp.eliteStatus === '360'
        || ffp.endorsements.includes('sky-club-individual')
        || ffp.endorsements.includes('sky-club-executive')
        || ffp.creditCards.includes('amex-cent');

    const creditCardVisits =
        ffp.travelYear === '2025'
        && !unlimitedSkyClubVisits
        && [
            ffp.creditCards.includes('dl-reserve') ? 15 : 0,
            ffp.creditCards.includes('dl-reserve-business') ? 15 : 0,
            ffp.creditCards.includes('amex-plat') ? 10 : 0,
            ffp.creditCards.includes('amex-plat-business') ? 10 : 0,
        ].reduce((a, b) => a + b);

    return (
        <div className="ffppicker">
            <Row>
                <Col md={6} xl={3}>
                    <Form.Group className='mb-4'>
                        <Form.Label htmlFor="ffp">
                            <span className="d-none d-lg-inline">Frequent Flyer</span>
                            {' '}
                            Program
                        </Form.Label>
                        <Form.Select name="ffp" value={ffp.program} onChange={onProgramChange}>
                            <option value="DL">Delta SkyMiles</option>
                            <option value="AFKL">Air France-KLM Flying Blue</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                {ffp.program === 'DL' && (
                    <Col md={6} xl={3}>
                        <Form.Group className='mb-4'>
                            <Form.Label htmlFor="elite-status">Medallion Status</Form.Label>
                            <Form.Select name="elite-status" value={ffp.eliteStatus} onChange={onMedallionStatusChange}>
                                <option value="">Non-Member</option>
                                <option value="SM">SkyMiles Member</option>
                                <option value="FO">Silver Medallion</option>
                                <option value="GM">Gold Medallion</option>
                                <option value="PM">Platinum Medallion</option>
                                <option value="DM">Diamond Medallion</option>
                                <option value="360">Delta 360&deg;</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                )}
                {ffp.program === 'AFKL' && (
                    <Col md={6} xl={3}>
                        <Form.Group className='mb-4'>
                            <Form.Label htmlFor="elite-status">Level</Form.Label>
                            <Form.Select name="elite-status" value={ffp.eliteStatus} onChange={onMedallionStatusChange}>
                                <option value="">Non-Member</option>
                                <option value="explorer">Explorer</option>
                                <option value="silver">Silver</option>
                                <option value="gold">Gold</option>
                                <option value="plat">Platinum</option>
                                <option value="ulti">Platinum Ultimate</option>
                                <option value="club">Club 2000</option>
                                <option value="hipp">Hippocampe</option>
                                <option value="skip">Skipper of the Flying Dutchman</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                )}
                {ffp.program === 'DL' && (
                    <Col md={4} xl={2}>
                        <Form.Group className='mb-4'>
                            <Form.Label htmlFor="ffp">
                                <span className="d-none d-lg-inline">Travel</span>
                                {' '}
                                Year
                            </Form.Label>
                            <Form.Select name="ffp-year" value={ffp.travelYear} onChange={onTravelYearChange}>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                )}
                <Col md={ffp.program === 'DL' ? 4 : 6} xl={ffp.program === 'DL' ? 2 : 3}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="fare-class-airline">Default Airline</Form.Label>
                        <Form.Control
                            name="fare-class-airline"
                            placeholder={ffp.program === 'AFKL' ? 'AF' : 'DL'}
                            style={{ textTransform: "uppercase" }}
                            value={props.defaultFareClass?.airline || ''}
                            onChange={onDefaultFareClassValueChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={ffp.program === 'DL' ? 4 : 6} xl={ffp.program === 'DL' ? 2 : 3}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="fare-class-airline">Default Fare Class</Form.Label>
                        <Form.Control
                            name="fare-class"
                            placeholder="Y"
                            style={{ textTransform: "uppercase" }}
                            value={props.defaultFareClass?.fareClass || ''}
                            onChange={onDefaultFareClassValueChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            {ffp.program === 'DL' && (
                <>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="elite-status">Sky Club Membership</Form.Label>
                        <Row>
                            <Col xl={6}>
                                <Form.Check
                                    label="Delta Sky Club Individual Member"
                                    name="credit-card"
                                    type="checkbox"
                                    id="sky-club-individual"
                                    value="sky-club-individual"
                                    checked={ffp.endorsements.includes("sky-club-individual")}
                                    onChange={onSkyClubChange}
                                />
                            </Col>
                            <Col xl={6}>
                                <Form.Check
                                    label="Delta Sky Club Executive Member"
                                    name="credit-card"
                                    type="checkbox"
                                    id="sky-club-executive"
                                    value="sky-club-executive"
                                    checked={ffp.endorsements.includes("sky-club-executive")}
                                    onChange={onSkyClubChange}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </>
            )}

            <Form.Group className="mb-4">
                <Form.Label htmlFor="credit-card">Credit Card</Form.Label>
                <Row>
                    <Col xl={6}>
                        <Form.Check
                            label={
                                <div>
                                    <div>Delta SkyMiles Reserve American Express Card</div>
                                    {ffp.travelYear === '2025' && (
                                        <small className="muted">
                                            Fifteen days of Sky Club access per year, unlimited with $75k/year card spend.
                                        </small>
                                    )}
                                </div>
                            }
                            name="credit-card"
                            type="checkbox"
                            id="dl-reserve"
                            value="dl-reserve"
                            onChange={onCreditCardChange}
                            checked={ffp.creditCards.includes('dl-reserve')}
                        />
                    </Col>
                    <Col xl={6}>
                        <Form.Check
                            label={
                                <div>
                                    <div>Delta SkyMiles Reserve Business American Express Card</div>
                                    {ffp.travelYear === '2025' && (
                                        <small className="muted">
                                            Fifteen days of Sky Club access per year, unlimited with $75k/year card spend.
                                        </small>
                                    )}
                                </div>
                            }
                            name="credit-card"
                            type="checkbox"
                            id="dl-reserve-business"
                            value="dl-reserve-business"
                            onChange={onCreditCardChange}
                            checked={ffp.creditCards.includes('dl-reserve-business')}
                        />
                    </Col>
                    <Col xl={6}>
                        <Form.Check
                            label={
                                <div>
                                    <div>American Express Platinum</div>
                                    {ffp.travelYear === '2025' && (
                                        <small className="muted">
                                            Ten days of Sky Club access per year, unlimited with $75k/year card spend.
                                        </small>
                                    )}
                                </div>
                            }
                            name="credit-card"
                            type="checkbox"
                            id="amex-plat"
                            value="amex-plat"
                            onChange={onCreditCardChange}
                            checked={ffp.creditCards.includes('amex-plat')}
                        />
                    </Col>
                    <Col xl={6}>
                        <Form.Check
                            label={
                                <div>
                                    <div>American Express Business Platinum</div>
                                    {ffp.travelYear === '2025' && (
                                        <small className="muted">
                                            Ten days of Sky Club access per year, unlimited with $75k/year card spend.
                                        </small>
                                    )}
                                </div>
                            }
                            name="credit-card"
                            type="checkbox"
                            id="amex-plat-business"
                            value="amex-plat-business"
                            onChange={onCreditCardChange}
                            checked={ffp.creditCards.includes('amex-plat-business')}
                        />
                    </Col>
                    <Col xl={6}>
                        <Form.Check
                            label={
                                <div>
                                    <div>American Express Centurion</div>
                                    {ffp.travelYear === '2025' && (
                                        <small className="muted">
                                            Unlimited Sky Club visits
                                        </small>
                                    )}
                                </div>
                            }
                            name="credit-card"
                            type="checkbox"
                            id="amex-cent"
                            value="amex-cent"
                            onChange={onCreditCardChange}
                            checked={ffp.creditCards.includes('amex-cent')}
                        />
                    </Col>
                </Row>
                {ffp.program === "AFKL" && (
                    <>
                        <h3 className="h6 mt-4">
                            Canadian Credit Cards &#x1F1E8;&#x1F1E6;
                        </h3>
                        <Row>
                            <Col xl={6}>
                                <Form.Check
                                    label="Air France KLM World Elite MasterCard powered by Brim Financial"
                                    name="credit-card"
                                    type="checkbox"
                                    id="brim-afkl"
                                    value="brim-afkl"
                                    onChange={onCreditCardChange}
                                    checked={ffp.creditCards.includes('brim-afkl')}
                                />
                            </Col>
                        </Row>
                    </>
                )}

                {creditCardVisits ? (
                    <div>You will receive <strong>{creditCardVisits}</strong> days of Sky Club access per year from your credit cards.</div>
                ) : null}
                {ffp.travelYear === '2025' && ffp.creditCards.includes('amex-cent') ? (
                    <div>You will receive <strong>unlimited</strong> Sky Club visits per year from your American Express Centurion card.</div>
                ) : null}
            </Form.Group>
        </div>
    );
};

export default FFPPicker;
