import { Container } from 'react-bootstrap';
import Calculator from './components/Calculator';
import EarningCharts from './components/EarningCharts';
import KnownIssues from './components/KnownIssues';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { CookieBanner } from './components/CookieBanner';

function App() {
    const navigate = useNavigate();

    return (
        <div className="App">
            <header>
                <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light-blur">
                    <div className="container">
                        <Link className="navbar-brand" to="/">qualifyingmiles.com</Link>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                <li className="nav-item">
                                    <Link className='nav-link' to="/">Calculator</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="charts">Earning Charts</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main className="flex-shrink-0">
                <Container>
                    <Routes>
                        <Route index element={<Calculator />} />
                        <Route path="charts" element={<EarningCharts />} />
                        <Route path="known-issues" element={<KnownIssues />} />
                        <Route path="privacy" element={<PrivacyPolicy />} />
                        <Route path="terms-of-service" element={<TermsOfService />} />
                    </Routes>

                    <hr className="my-4" />

                    <div className="footer">
                        <p className="small muted">
                            Not affiliated with Delta Air Lines, Inc., Soci&eacute;t&eacute; Air France, S.A.,
                            Koninklijke Luchtvaart Maatschappij N.V., SkyTeam Airline Alliance Management
                            Co&ouml;peratie U.A., or any other airline.
                        </p>
                        <p className="small muted">
                            Not affiliated with American Express Company, Brim Financial Inc., or any other financial
                            services company.
                        </p>
                        <p className="small muted">
                            For entertainment purposes only. Accuracy is not guaranteed &mdash; see the{' '}
                            <Link to="known-issues" onClick={() => navigate("known-issues")} style={{ fontWeight: 'bold' }}>
                                list of known issues
                            </Link>, but other issues not listed may be present.
                        </p>
                        <p className="small muted">
                            All trademarks, logos, and brand names are the property of their respective owners. All company,
                            product and service names used in this website are for identification purposes only. Use of
                            these names, trademarks and brands does not imply endorsement.
                        </p>
                        <div className="small muted">
                            <Link to="privacy" onClick={() => navigate("privacy")}>Privacy Policy</Link>
                            <span className="mx-4">&middot;</span>
                            <Link to="terms-of-service" onClick={() => navigate("terms-of-service")}>
                                Terms of Service
                            </Link>
                        </div>
                    </div>
                </Container>
            </main>

            <CookieBanner />
        </div>
    );
}

export default App;
