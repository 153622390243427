import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import FFPInformation from "../interfaces/FFPInformation";
import ControlledTextArea from "./ControlledTextArea";
import { calculateLounge } from "../lounge-access";
import { AlaskanCarriers, CapeAirDestinations, DLEliteBonuses, calculateEarning as calculateDeltaEarning } from "../delta-earning-calculator";
import { AFKLEliteBonuses, calculateEarning as calculateAFKLEarning } from "../afkl-earning-calculator";
import { useSearchParams } from "react-router-dom";

import Airports from "../airports.json";

import "./FlightBuilder.css";

const brandColors: { [brand: string]: string } = {
    "DL": "rgb(0, 51, 102)",
    "Delta One": "rgb(0, 51, 102)",
    "AM": "rgb(11, 35, 67)",
    "AF": "rgb(0, 33, 87)",
    "VS": "rgb(218, 5, 48)",
    "KE": "rgb(113, 203, 235)",
    "KL": "rgb(0, 161, 222)",
    "AMEX": "rgb(0, 111, 207)",
    "LA": "rgb(24, 26, 149)",
    "WS": "rgb(4, 172, 164)",
    "KQ": "rgb(226, 7, 20)",
    "AR": "rgb(0, 119, 194)",
    "CI": "rgb(62, 98, 173)",
    "VN": "rgb(6, 133, 170)",
    "SkyTeam": "rgb(11, 23, 97)",
    "Escape": "rgb(133, 1, 87)",
    "Plaza": "rgb(113, 27, 69)",
};

interface Properties {
    ffpInformation: FFPInformation;
    defaultFareClass: {
        airline: string;
        fareClass: string;
    } | null;
};

function greatCircleDistance(a?: { lat: number, lng: number }, b?: { lat: number, lng: number }): number | undefined {
    if (!a || !b)
        return undefined;

    const R = 6371e3; // meters;
    const phi1 = a.lat * Math.PI / 180;
    const phi2 = b.lat * Math.PI / 180;
    const dphi = (b.lat - a.lat) * Math.PI / 180;
    const dlambda = (b.lng - a.lng) * Math.PI / 180;

    const a0 = Math.sin(dphi / 2) * Math.sin(dphi / 2)
        + Math.cos(phi1) * Math.cos(phi2) * Math.sin(dlambda / 2) * Math.sin(dlambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a0), Math.sqrt(1 - a0));
    return R * c * 100 / 2.54 / 12 / 5280;
}

const FlightBuilder = (props: Properties) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get('q');
    const [startingRDMs, setStartingRDMs] = useState<string>(searchParams.get('sr') || '');
    const [startingMQMs, setStartingMQMs] = useState<string>(searchParams.get('sm') || '');
    const [startingMQDs, setStartingMQDs] = useState<string>(searchParams.get('sd') || '');
    const [startingXP, setStartingXP] = useState<string>(searchParams.get('sx') || '');
    const [startingUXP, setStartingUXP] = useState<string>(searchParams.get('su') || '');

    const [showInitial, setShowInitial] = useState<boolean>(!!(startingRDMs || startingMQMs || startingMQDs || startingXP || startingUXP));

    const onAddStartingValuesClick = useCallback(() => {
        setShowInitial(!showInitial);
    }, [showInitial]);

    const onStartingRDMsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStartingRDMs(e.target.value);
    }, []);

    const onStartingMQMsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStartingMQMs(e.target.value);
    }, []);

    const onStartingMQDsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStartingMQDs(e.target.value);
    }, []);

    const onStartingXPChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStartingXP(e.target.value);
    }, []);

    const onStartingUXPChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStartingUXP(e.target.value);
    }, []);

    const [flightInput, setFlightInput] = useState<string>(query || '');
    const [showInstructions, setShowInstructions] = useState<boolean>(false);

    const eliteBonuses: { [status: string]: number } =
        props.ffpInformation.program === 'DL' ? DLEliteBonuses
        : props.ffpInformation.program === 'AFKL' ? AFKLEliteBonuses
        : {};
    const eliteBonusRate = eliteBonuses[props.ffpInformation.eliteStatus ?? ""] || 0;

    const calculateEarning = props.ffpInformation.program === 'DL' ? calculateDeltaEarning : calculateAFKLEarning;

    const brimBonusRate = props.ffpInformation.creditCards.includes("brim-afkl") ? 5 : 0;

    // Parse flight input.
    const flights = useMemo(() =>
        flightInput
            .toUpperCase()
            .split('\n')
            .map(x => x.trim())
            .filter(x => !!x)
            .map(line => line.split(/\s+/)[0]
                .split('//')
                .flatMap(jaw => jaw
                    .split('-')
                    .map(fragment => /^[A-Z]{3}$/.test(fragment)
                        ? { "airport": fragment }
                        : fragment.match(/^(?<marketing>[A-Z0-9]{2})\*?(\.(?<marketingFareClass>[A-Z])?|(\.(?<upgradedFareClass>[A-Z]{1,2}\$?)(\.(?<marketingFareClass2>[A-Z]))?))?(\/(?<operating>[A-Z0-9]{2}))?(\.(?<operatingFareClass>[A-Z])?)?$/)?.groups
                    )
                    .map(g => g === undefined ? {} : g)
                    .map(g => ({
                        ...g,
                        marketing: g['marketing'] || props.defaultFareClass?.airline || undefined,
                        marketingFareClass: g['marketingFareClass'] || g['marketingFareClass2'] || props.defaultFareClass?.fareClass,
                        upgradedFareClass: g['upgradedFareClass']?.slice(-1) === "$" ? g['upgradedFareClass']?.slice(0, -1) : g['upgradedFareClass'],
                        paidUpgrade: (g['upgradedFareClass']?.slice(-1) === "$" || false) ? "true" : "false",
                    }) as { [key: string]: string })
                    .map((x, i, a) => [x, a[i + 1], a[i + 2]])
                    .map(([a, b, c]) => (a?.airport && b?.airport)
                        ? {
                            "from": a.airport,
                            "to": b.airport,
                            "rawLineFare": line.split(/\s+/).slice(1),
                            "lineFare": line.split(/\s+/).slice(1).map(fare =>
                                    (fare[0] === '$' || fare[0] === '€') ? parseFloat(fare.slice(1))
                                    : fare.endsWith('€') ? parseFloat(fare.slice(0, -1))
                                    : (fare.endsWith('SM') || fare.endsWith('SKM') || fare.endsWith('SKYMILES')) ? parseFloat(fare) / 100
                                    : 0,
                                )
                                .filter(x => !Number.isNaN(x))
                                .reduce((a, b) => a + b, 0),
                            "marketing": props.defaultFareClass?.airline || undefined,
                            "marketingFareClass": props.defaultFareClass?.fareClass || undefined,
                        }
                        : (a?.airport && c?.airport && b?.marketing) ? {
                            "from": a.airport,
                            "to": c.airport,
                            "rawLineFare": line.split(/\s+/).slice(1),
                            "lineFare": line.split(/\s+/).slice(1).map(fare =>
                                (fare[0] === '$' || fare[0] === '€') ? parseFloat(fare.slice(1))
                                    : fare.endsWith('€') ? parseFloat(fare.slice(0, -1))
                                    : (fare.endsWith('SM') || fare.endsWith('SKM') || fare.endsWith('RDM') || fare.endsWith('SKYMILES')) ? parseFloat(fare) / 100
                                    : 0,
                                )
                                .filter(x => !Number.isNaN(x))
                                .reduce((a, b) => a + b, 0),
                            ...b,
                        }
                        : null)
                    .filter(x => !!x)
                    .map((route) => ({
                        ...route,
                        fromAirport: Airports.filter(a => a.iata_code === route?.from)[0],
                        toAirport: Airports.filter(a => a.iata_code === route?.to)[0],
                    }))
                    .map((route) => ({
                        ...route,
                        distance: Math.ceil(greatCircleDistance(route.fromAirport?._geoloc, route.toAirport?._geoloc) ?? 0),
                        fareClass: (route as any).marketingFareClass || (route as any).operatingFareClass,
                    }))
                    .map((route) => ({
                        ...route,
                        ...calculateEarning(route as any, props.ffpInformation),
                    })))
            ).map(line => line
                .map(flight => ({
                    ...flight,
                    ...calculateLounge(props.ffpInformation, line as any, flight as any),
                }))
                .map((flight: any) =>
                        props.ffpInformation.program === "DL" && (
                        ((flight.marketing === undefined || flight.marketing === "DL") && flight.fareClass !== "E")
                        || (flight.marketing === "9K" && (!flight.operating || flight.operating === "9K") && (CapeAirDestinations.includes(flight.from) || CapeAirDestinations.includes(flight.to)))
                        || (AlaskanCarriers.includes(flight.marketing ?? "") && (!flight.operating || AlaskanCarriers.includes(flight.operating ?? ""))))
                    ? ({
                        ...flight,
                        rdms: ((!flight.marketing || flight.marketing === "DL") && ["N", "R", "O"].includes(flight.fareClass)) ? 0 : (5 * (1 + eliteBonusRate)) * Math.round((flight.lineFare || 0) * (flight.distance ?? 0) / line.map(f => f.distance || 0).reduce((a, x) => a + x, 0)) || 0,
                        mqds: Math.round((flight.lineFare || 0) * (flight.distance ?? 0) / line.map(f => f.distance || 0).reduce((a, x) => a + x, 0)) || 0,
                    })
                    : props.ffpInformation.program === "AFKL"
                    ? ({
                        ...flight,
                        rdms: (!flight.marketing || flight.marketing === "AF" || flight.marketing === "KL") ? (4 * (1 + eliteBonusRate) + brimBonusRate) * Math.round((flight.lineFare || 0) * (flight.distance ?? 0) / line.map(f => f.distance || 0).reduce((a, x) => a + x, 0)) : flight.rdms,
                    }
                    ) : flight
                )
            ).filter(a => a.length > 0),
            [brimBonusRate, calculateEarning, eliteBonusRate, flightInput, props.defaultFareClass?.airline, props.defaultFareClass?.fareClass, props.ffpInformation]);

    const showCarrier = flights.flat().filter((x: any) => !!x.marketing).length > 0;
    const showFareClass = flights.flat().filter((x: any) => !!x.fareClass || !!x.upgradedFareClass).length > 0;
    const showLounge =
        ["GM", "PM", "DM", "360"].includes(props.ffpInformation.eliteStatus || "")
        || props.ffpInformation.endorsements.includes("sky-club-individual")
        || props.ffpInformation.endorsements.includes("sky-club-executive")
        || props.ffpInformation.creditCards.includes("cc-dl-reserve")
        || props.ffpInformation.creditCards.includes("cc-dl-reserve-business")
        || props.ffpInformation.creditCards.includes("cc-amex-plat")
        || props.ffpInformation.creditCards.includes("cc-amex-cent")
        || flights.flat().filter(f => Object.keys(f.lounges).length > 0).length > 0
        ;
    const showSubtotals = flights.filter(a => a.length > 0).length > 1;
    
    const onExampleClick = (route: string) => () => {
        setFlightInput(route);
        setShowInstructions(false);
    };

    const ExampleRoute = ({ route }: { route: string }) => (
        <div className="d-flex mb-4">
            <pre className="flex-grow-1 mb-0">{route}</pre>
            <Button size="sm" onClick={onExampleClick(route)}>Try It</Button>
        </div>
    );

    const flightOutput = useMemo(() => 
        flights
            .map(line => line
                .reduce(
                    (jaws, flight) => (jaws.length === 0 || jaws[jaws.length-1]?.slice(-1)[0]?.to !== flight.from)
                        ? [...jaws, [flight]]
                        : [...jaws.slice(0, -1), [...jaws[jaws.length-1], flight]],
                    [] as any[],
                )
                .map((jaw: any[]) => (jaw[0]?.from || '') + jaw.map(flight => {
                    let joiner = '';
                    if (flight.marketing) {
                        joiner = `-${flight.marketing}`;
                        if (flight.upgradedFareClass) {
                            joiner += `.${flight.upgradedFareClass}`;
                            if (flight.paidUpgrade === "true")
                                joiner += "$";
                            if (flight.marketingFareClass)
                                joiner += `.${flight.marketingFareClass}`;
                        }
                        else if (flight.marketingFareClass)
                            joiner += `.${flight.marketingFareClass}`;
                    }
                    if (flight.operating) {
                        joiner += `/${flight.operating}`;
                        if (flight.operatingFareClass)
                            joiner += `.${flight.operatingFareClass}`;
                    }

                    return `${joiner}-${flight.to}`;
                }).join(''))
                .join('//') + ' ' + line[0]?.rawLineFare?.join(' ')
            )
            .map(line => line?.trim())
            .join('\n'),
        [flights]);

    useEffect(() => {
        if (query !== flightOutput || (searchParams.get('sr') || '') !== startingRDMs || (searchParams.get('sm') || '') !== startingMQMs || (searchParams.get('sd') || '') !== startingMQDs || (searchParams.get('sx') || '') !== startingXP || (searchParams.get('su') || '') !== startingUXP)
            setSearchParams(Object.fromEntries(Object.entries({
                's': searchParams.get('s') || '',
                'p': searchParams.get('p') || '',
                'cc': searchParams.getAll('cc') || [],
                'e': searchParams.getAll('e') || [],
                'y': searchParams.getAll('y') || '',
                'q': flightOutput,
                'a': searchParams.get('a') || '',
                'f': searchParams.get('f') || '',
                'sr': startingRDMs || '',
                'sm': startingMQMs || '',
                'sd': startingMQDs || '',
                'sx': startingXP || '',
                'su': startingUXP || '',
            }).filter(([_, value]) => !!value)));
    }, [query, flightOutput, searchParams, setSearchParams, startingRDMs, startingMQMs, startingMQDs, startingXP, startingUXP]);

    return (
        <div className="flight-builder">
            {/* <FlightMap routes={[]} />*/}

            <div className="d-flex align-items-start">
                <ControlledTextArea
                    aria-label="Flight route input"
                    className="form-control mb-4"
                    rows={Math.max(1, flightInput.split('\n').length)}
                    style={{ textTransform: "uppercase", fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace", resize: "none", marginBottom: 0, marginRight: 8 }}
                    value={flightInput}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFlightInput(e.target.value?.toUpperCase())}
                />
                <div className="d-flex flex-column flex-lg-row" style={{ gap: "0.25em" }}>
                    <Button onClick={() => setShowInstructions(true)}>
                        <span className="d-none d-lg-inline">Input&nbsp;Instructions</span>
                        <span className="d-inline d-lg-none">Instructions</span>
                    </Button>
                    <Button className={showInitial ? "active" : ""} aria-pressed={showInitial ? "true" : undefined} variant="light" onClick={onAddStartingValuesClick}>
                        Starting&nbsp;Values&hellip;
                    </Button>
                </div>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th className="d-none d-lg-table-cell" colSpan={3} style={{ width: 20 }}>Route</th>
                        <th style={{ display: showCarrier ? "table-cell" : "none", width: 80 }}>Carrier</th>
                        <th style={{ display: showFareClass ? "table-cell" : "none", whiteSpace: "nowrap", width: 80 }}>Fare Class</th>
                        <th style={{ display: showLounge ? "table-cell" : "none" }}>Lounge</th>
                        <th>Distance</th>
                        <th>RDMs</th>
                        {props.ffpInformation.program === 'DL' && props.ffpInformation.travelYear === '2023' && <th>MQMs</th>}
                        {props.ffpInformation.program === 'DL' && <th>MQDs</th>}
                        {props.ffpInformation.program === 'AFKL' && <th>XP</th>}
                        {props.ffpInformation.program === 'AFKL' && props.ffpInformation.eliteStatus === "ulti" && <th>UXP</th>}
                    </tr>
                </thead>

                <tbody>
                    {showInitial && (
                        <tr>
                            <th className="d-none d-lg-table-cell" colSpan={3 + [showCarrier, showFareClass, showLounge].filter(x => !!x).length} style={{ textAlign: "right", verticalAlign: "middle" }}>Starting Values:</th>
                            <th></th>
                            <th><input type="text" className="form-control form-control-sm" placeholder="0 RDMs" value={startingRDMs} onChange={onStartingRDMsChange} /></th>
                            {props.ffpInformation.program === 'DL' && props.ffpInformation.travelYear === '2023' && (
                                <th>
                                    <input type="text" className="form-control form-control-sm" placeholder="0 MQMs" value={startingMQMs} onChange={onStartingMQMsChange} />
                                </th>
                            )}
                            {props.ffpInformation.program === 'DL' && (
                                <th>
                                    <input type="text" className="form-control form-control-sm" placeholder="0 MQDs" value={startingMQDs} onChange={onStartingMQDsChange} />
                                </th>
                            )}
                            {props.ffpInformation.program === 'AFKL' && (
                                <th>
                                    <input type="text" className="form-control form-control-sm" placeholder="0 XP" value={startingXP} onChange={onStartingXPChange} />
                                </th>
                            )}
                            {props.ffpInformation.program === 'AFKL' && props.ffpInformation.eliteStatus === "ulti" && (
                                <th>
                                    <input type="text" className="form-control form-control-sm" placeholder="0 XP" value={startingUXP} onChange={onStartingUXPChange} />
                                </th>
                            )}
                        </tr>
                    )}

                    {Object.entries(flights).length > 0 && flights.map((line, index) => (
                        <React.Fragment key={`line-${index}`}>
                            {index > 0 && (
                                <tr>
                                    <td colSpan={9}>
                                    </td>
                                </tr>
                            )}
                            {line.map((flight: any, flightIndex) => (
                                <React.Fragment key={`flight-${index}-${flightIndex}`}>
                                    <tr className="d-lg-none">
                                        <td colSpan={8} style={{ paddingBottom: 0 }}>
                                            <span style={{ width: 50, fontWeight: "bold", whiteSpace: "nowrap" }}>
                                                {flight.fromAirport?.city ? `${flight.fromAirport?.city} (${flight.from})` : flight.from}
                                            </span>
                                            <span style={{ width: 20, marginLeft: 8, marginRight: 8 }}>to</span>
                                            <span style={{ width: 50, fontWeight: "bold", whiteSpace: "nowrap" }}>
                                                {flight.toAirport?.city ? `${flight.toAirport?.city} (${flight.to})` : flight.to}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="d-none d-lg-table-cell" style={{ width: 50, fontWeight: "bold", whiteSpace: "nowrap" }}>
                                            {flight.fromAirport?.city ? `${flight.fromAirport?.city} (${flight.from})` : flight.from}
                                        </td>
                                        <td className="d-none d-lg-table-cell" style={{ width: 20 }}>to</td>
                                        <td className="d-none d-lg-table-cell" style={{ width: 50, fontWeight: "bold", whiteSpace: "nowrap" }}>
                                            {flight.toAirport?.city ? `${flight.toAirport?.city} (${flight.to})` : flight.to}
                                        </td>
                                        <td style={{ display: showCarrier ? "table-cell" : "none" }}>
                                            {(flight.marketing && flight.operating ? `${flight.marketing}*/${flight.operating}` : flight.marketing) || <span className="muted">{props.ffpInformation.program === 'AFKL' ? 'AF' : 'DL'}</span>}
                                        </td>
                                        <td style={{ display: showFareClass ? "table-cell" : "none" }}>
                                            {flight.upgradedFareClass ? `${flight.upgradedFareClass}/${flight.fareClass ?? '?'}` : flight.fareClass}
                                        </td>
                                        <td style={{ display: showLounge ? "table-cell" : "none" }}>
                                            {Object.entries(flight.lounges ?? {}).map(([key]: [string, any]) => (
                                                <span
                                                    key={key}
                                                    className="badge"
                                                    style={{
                                                        padding: "2px 4px", marginRight: 3, backgroundColor: brandColors[key] || 'rgb(47, 60, 72)' }}
                                                >
                                                    {key}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{flight.distance} mi</td>
                                        <td>{flight.rdms !== undefined ? flight.rdms : <>&mdash;</>}</td>
                                        {props.ffpInformation.program === 'DL' && props.ffpInformation.travelYear === '2023' && <td>{flight.mqms !== undefined ? flight.mqms : <>&mdash;</>}</td>}
                                        {props.ffpInformation.program === 'DL' && <td>{flight.mqds !== undefined ? `$${flight.mqds}` : <>&mdash;</>}</td>}
                                        {props.ffpInformation.program === 'AFKL' && <td>{flight.xp}</td>}
                                        {props.ffpInformation.program === 'AFKL' && props.ffpInformation.eliteStatus === "ulti" && <td>{flight.uxp}</td>}
                                    </tr>
                                </React.Fragment>
                            ))}
                            {showSubtotals && (
                                <tr style={{ fontWeight: 'bold' }}>
                                    <td className="d-none d-lg-table-cell" colSpan={3 + [showCarrier, showFareClass, showLounge].filter(x => x).length} style={{ textAlign: 'right' }}>Subtotal:</td>
                                    <td className="d-table-cell d-lg-none" colSpan={[showCarrier, showFareClass, showLounge].filter(x => x).length} style={{ textAlign: 'right' }}>Subtotal:</td>
                                    <td>{`${line.reduce((a, b) => a + b.distance, 0)} mi`}</td>
                                    <td>{line.reduce((a, b) => a + b.rdms, 0)}</td>
                                    {props.ffpInformation.program === 'DL' && props.ffpInformation.travelYear === '2023' && (
                                        <td>{line.reduce((a, b) => a + b.mqms, 0)}</td>
                                    )}
                                    {props.ffpInformation.program === 'DL' && (
                                        <td>{`$${line.reduce((a, b) => a + b.mqds, 0)}`}</td>
                                    )}
                                    {props.ffpInformation.program === 'AFKL' && (
                                        <td>{line.reduce((a, b) => a + b.xp, 0)}</td>
                                    )}
                                    {props.ffpInformation.program === 'AFKL' && props.ffpInformation.eliteStatus === "ulti" && (
                                        <td>{line.reduce((a, b) => a + b.uxp, 0)}</td>
                                    )}
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>

                <tfoot>
                    <tr>
                        <th colSpan={10}></th>
                    </tr>
                    <tr>
                        <th className="d-none d-lg-table-cell" colSpan={2}></th>
                        <th style={{ display: showCarrier ? "table-cell" : "none" }}></th>
                        <th style={{ display: showFareClass ? "table-cell" : "none" }}></th>
                        <th style={{ display: showLounge ? "table-cell" : "none" }}></th>
                        <th className="d-none d-lg-table-cell" style={{ textAlign: "right" }}>Total:</th>
                        <th>{flights.flat().map(f => (f.distance || 0)).reduce((a, b) => a + b, 0)} mi</th>
                        <th>{flights.flat().map(f => (f.rdms || 0)).reduce((a, b) => a + b, 0) + (parseInt(startingRDMs, 10) || 0)}</th>
                        {props.ffpInformation.program === 'DL' && props.ffpInformation.travelYear === '2023' && <th>{flights.flat().map(f => (f.mqms || 0)).reduce((a, b) => a + b, 0) + (parseInt(startingMQMs, 10) || 0)}</th>}
                        {props.ffpInformation.program === 'DL' && <th>${flights.flat().map(f => (f.mqds || 0)).reduce((a, b) => a + b, 0) + (parseInt(startingMQDs, 10) || 0)}</th>}
                        {props.ffpInformation.program === 'AFKL' && <th>{flights.flat().map(f => (f.xp || 0)).reduce((a, b) => a + b, 0)  + (parseInt(startingXP, 10) || 0)} XP</th>}
                        {props.ffpInformation.program === 'AFKL' && props.ffpInformation.eliteStatus === "ulti" && <th>{flights.flat().map(f => (f.uxp || 0)).reduce((a, b) => a + b, 0) + (parseInt(startingUXP, 10) || 0)} UXP</th>}
                    </tr>
                </tfoot>
            </Table>

            <Modal show={showInstructions} onHide={() => setShowInstructions(false)} size="xl">
                <Modal.Header>Flight Syntax</Modal.Header>
                <Modal.Body>
                    <h2 className="h6" style={{ fontWeight: "bold" }}>Flight Path</h2>
                    
                    <p>If you are flying all-Delta flights and just want to know the distance, you can put in your routing:</p>
                    <ExampleRoute route="LAX-JFK-LHR" />

                    <h2 className="h6 mt-2" style={{ fontWeight: "bold" }}>Fare</h2>
                    
                    <p>If you know your base fare (plus carrier imposed surcharges if applicable), put it at the end of the input to calculate RDMs and MQDs.</p>
                    <ExampleRoute route="LAX-JFK-LHR $500" />
                    
                    <p>You can use multiple lines to represent multiple fare components.</p>
                    <ExampleRoute route={'LAX-JFK-LHR $500\nLHR-SEA-LAX $400'} />
                    
                    <p>Open jaw itineraries are supported by separating airports with <code>/</code>.</p>
                    <ExampleRoute route={'BOS-AMS//LHR-BOS'} />

                    <h2 className="h6 mt-2" style={{ fontWeight: "bold" }}>Multiple Airlines</h2>
                    
                    <p>The default airline is Delta (marketed and operated). If your itinerary includes flights marketed and operated by other airlines, you can use their IATA code.</p>
                    <ExampleRoute route="LAX-JFK-VS-LHR $500" />
                    
                    <p>You can specify the fare class by separating it from the airline code with a dot. This will allow you to estimate partner earnings.</p>
                    <ExampleRoute route="LAX-DL.W-JFK-VS.V-LHR $800" />

                    <p>If the marketing and operating carriers differ, you can separate them with a slash (marketing / operating).</p>
                    <ExampleRoute route="LAX-VS.V/DL-JFK-VS.V-LHR $800" />

                    <p>If you are flying in a premium cabin (as determined by your fare class), SkyTeam lounges will be shown.</p>
                    <ExampleRoute route="ATL-DL.J-SFO-KE.J-ICN $2500" />

                    <p>You can use any IATA airline code, though only Delta partners will earn SkyMiles and qualify for Medallion status</p>
                    <ExampleRoute route="BOS-AF.N-CDG-AF.L-OPO-KL.L/HV-AMS-KL.N/DL-BOS $347" />

                    <h2 className="h6" style={{ fontWeight: "bold" }}>Upgrades</h2>
                    <p>If you receive a <u>complimentary upgrade</u>, an <u>upgrade with a RUC or GUC</u>, or a <u>Mileage Upgrade Award</u> on a Delta-marketed and Delta-operated flight to Comfort+, Premium Select, or First Class/Delta One, you can enter your upgraded fare class before your purchased fare class separated by a dot. Earnings will be based on your purchased fare class.</p>
                    <ExampleRoute route="DEN-DL.OU.V-BOS $120" />

                    <p>If you use cash or miles to purchase a <u>paid upgrade</u> to Comfort+, Premium Select, or First Class/Delta One (excluding Mileage Upgrade Awards), you can indicate that with a <code>$</code> after your upgraded fare class. Earnings will be based on the upgraded fare class &mdash; this only affects MQM earnings so remember to add the number of dollars or SkyMiles that you paid to the end of the line.</p>
                    <ExampleRoute route="DEN-DL.OY$.V-BOS $120 20000SM" />
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" onClick={() => setShowInstructions(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FlightBuilder;
