import { Button, Container } from 'react-bootstrap';
import { useCallback, useMemo, useState } from 'react';

import './CookieBanner.css';

(window as any).dataLayer = (window as any).dataLayer || [];
function gtag(...args: any[]) { (window as any).dataLayer.push(args); }

export function CookieBanner() {
    const cookieConsent = useMemo(() => {
        return window.localStorage.getItem('cookieConsent');
    }, []);

    const [show, setShow] = useState<boolean>(cookieConsent === null || cookieConsent === undefined);

    const allowClick = useCallback(() => {
        window.localStorage.setItem('cookieConsent', 'allow');

        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
        });

        setShow(false);
    }, []);

    const denyClick = useCallback(() => {
        window.localStorage.setItem('cookieConsent', 'deny');
        setShow(false);
    }, []);

    console.log('cookieConsent', cookieConsent);

    if (!show)
        return null;

    return (
        <div className="CookieBanner">
            <Container>
                <div className="d-flex" style={{ alignItems: 'center', gap: '0.5rem' }}>
                    <div style={{ flex: 1 }}>
                        This website uses cookies to track usage and demographic data.
                    </div>
                    <Button size="sm" variant="light" onClick={denyClick}>Deny</Button>
                    <Button size="sm" color="default" onClick={allowClick}>Allow Cookies</Button>
                </div>
            </Container>
        </div>
    )
}
