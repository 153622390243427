const TermsOfService = () => (
    <>
        <h2>Terms of Service</h2>
        <p>
            Welcome to qualifyingmiles.com! Do read through the following Terms of Service (the "Terms") carefully before
            using the qualifyingmiles.com platform (the "Website") and any other services (collectively the "Services").
            By using our Website, you are agreeing to our Terms. The Terms are here for you so that you are aware of
            your legal rights when using our Services.
        </p>

        <h3>Agreement Terms</h3>
        <p>
            The Terms will be treated as a legally binding agreement made between qualifyingmiles.com (referred to
            individually or collectively as "qualifyingmiles.com", "we", "us", or "our") and you, the Website visitor,
            and/or registered user ("you"). We reserve the right at any time to:
        </p>
        <ul>
            <li>Change the Terms;</li>
            <li>Change the Website, including eliminating or discontinuing any content on or feature of the Website, restricting the hours of availability, or limiting the amount of use permitted; or</li>
            <li>Change any fees or charges for use of the Website, including instituting new or increased fees or charges for the use of the Website or Services or any feature thereof.</li>
        </ul>
        <p>
            Any changes we make will be effective immediately upon notice, which we may provide by any means, including,
            without limitation, posting on the Website or via electronic mail. Your use of the Website after such notice
            will be deemed acceptance of such changes. Be sure to review these Terms periodically to ensure familiarity
            with the most current version. Upon our request, you agree to sign a non-electronic version of this
            agreement.
        </p>
        <p>You must be at least 18 years of age to use the Website.</p>

        <h3>Ownership; Proprietary Notices</h3>
        <p style={{ fontWeight: 'bold' }}>&copy; 2023 qualifyingmiles.com</p>
        <p>
            The Website, including all pages within and all code related thereto, is the property of qualifyingmiles.com.
            No portion of the materials or code on these pages or anywhere on the Website may be reprinted or
            republished (other than as is necessary to view the page on your monitor) in any form without our express
            written permission.
        </p>

        <p>
            All trademarks and/or service marks used in this Website are the trademarks and/or service marks of their
            respective owners.
        </p>

        <p>
            The Website is owned and operated by us in conjunction with others pursuant to contractual arrangements.
            Unauthorized use of the materials on the Website may violate copyright, trademark, patent, and other laws
            and is prohibited. You acknowledge that you do not acquire any ownership rights by using the Website.
        </p>

        <h3>Warranty Information; Disclaimer</h3>
        <p>
            THIS WEBSITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY WEBSITE-RELATED SERVICE, OR
            ANY PRODUCT OR SERVICE LICENSED, PURCHASED, OR OTHERWISE MADE AVAILABLE THROUGH THE WEBSITE, IS PROVIDED
            "AS IS" WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. TO THE FULLEST
            EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, QUALIFYINGMILES.COM AND ITS SUBSIDIARIES, AFFILIATES,
            SUPPLIERS, SPONSORS, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
            NEITHER QUALIFYINGMILES.COM, ITS SUBSIDIARIES, AFFILIATES, SPONSORS, SUPPLIERS NOR AGENTS MAKES ANY
            REPRESENTATION AS TO THE RESULTS TO BE OBTAINED FROM USE OF THE WEBSITE OR THE WEBSITE-RELATED SERVICES.
        </p>

        <p>
            QUALIFYINGMILES.COM AND ITS SUBSIDIARIES, AFFILIATES, SUPPLIERS, AND AGENTS DO NOT WARRANT THAT YOUR USE OF
            THE WEBSITE OR THE SOFTWARE WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR
            THAT THE WEBSITE (OR THE SERVER(S) ON WHICH IT IS HOSTED) OR SOFTWARE ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR OBTAINING AND MAINTAINING ALL TELEPHONE, COMPUTER
            HARDWARE, AND OTHER EQUIPMENT NEEDED TO ACCESS AND USE THE WEBSITE AND ALL CHARGES RELATED THERETO. YOU
            ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE WEBSITE AND THE SOFTWARE AND YOUR RELIANCE THEREON.
            NO OPINION, ADVICE, OR STATEMENT, WHETHER MADE ON THE WEBSITE OR OTHERWISE, SHALL CREATE ANY WARRANTY.{' '}
            
            <strong>
                YOUR USE OF THE WEBSITE AND ANY MATERIALS PROVIDED THROUGH THE WEBSITE ARE ENTIRELY AT YOUR OWN RISK.
            </strong>
        </p>

        <p>
            You acknowledge that the Website could include inaccuracies or errors or materials that violate these Terms.
            Additionally, you acknowledge that unauthorized additions, deletions, and alterations could be made by third
            parties to the Website. Although we attempt to ensure the integrity and the accuracy of the Website, we make
            no guarantees whatsoever as to the Website's completeness or correctness. In the event that such a situation
            arises, please contact us at support@example.com with, if possible, a description of the material to be
            checked and the location (URL) where such material can be found on the Website, as well as information
            sufficient to enable us to contact you. We will try to address your concerns as soon as reasonably
            practicable. For copyright infringement claims, see the section on "Claims of Copyright Infringement".
        </p>

        <p>
            You acknowledge and agree that you are solely responsible for the form, content, and accuracy of any
            material you post to the Website. We neither warrant nor guarantee that any material on the site is
            accurate, nor do we necessarily check material that you supply for accuracy.
        </p>

        <h3>Limitation of Liability</h3>

        <p>
            NEITHER QUALIFYINGMILES.COM NOR ITS SUBSIDIARIES, AFFILIATES, SUPPLIERS, ADVERTISERS, AGENTS, OR SPONSORS ARE
            RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER
            DAMAGES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY
            WAY TO THE WEBSITE AND/OR CONTENT CONTAINED ON THE WEBSITE, OR ANY PRODUCT OR SERVICE PURCHASED THROUGH THE
            WEBSITE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE AND/OR CONTENT CONTAINED WITHIN THE WEBSITE
            IS TO STOP USING THE WEBSITE. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO PMCAL.NET FOR ALL DAMAGES, LOSSES,
            AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT, INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, OR OTHERWISE)
            SHALL BE THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE WEBSITE.
        </p>

        <h3>Acknowledgement</h3>
        <p>
            This Website may contain links to other websites operated by third parties ("Linked Sites"). We neither
            control nor endorse such other websites nor have we reviewed or approved any content that appears on the
            Linked Sites. You acknowledge that when you click on a link to visit a Linked Site, a frame may appear that
            contains our logo, advertisements, and/or other content selected by us. You acknowledge that we neither
            endorse nor are affiliated with the Linked Sites and are not responsible for any content that appears on the
            Linked Sites. You also acknowledge that the owner of the Linked Site neither endorses nor is affiliated with
            qualifyingmiles.com.
        </p>

        <h3>Availability; Indemnification</h3>

        <p>
            qualifyingmiles.com reserves the right to limit the availability of the Website and/or the provision of any
            product or service described thereon to any person, geographic area, or jurisdiction it so desires at any
            time and in our sole discretion and to limit the quantities of any such product or service that we provide.
        </p>

        <p>
            You agree to indemnify, defend and hold us, our affiliates, sponsors, officers, directors, employees,
            agents, and representatives harmless from and against any and all claims, damages, losses, costs (including
            reasonable attorneys' fees), or other expenses that arise directly or indirectly out of or from (a) your
            breach of this Agreement, (b) your violation of the Code of Conduct, and/or (c) your activities in
            connection with the Webite or Website-related services.
        </p>

        <p style={{ fontWeight: "bold" }}>
            In consideration of your use of the Services, you expressly agree to waive, release and hold Mileage
            Calculator, our employees, officers, and agents harmless from and against any and all claims or causes of
            action, and you hereby agree to voluntarily give up or waive any right that you may otherwise have to bring
            legal action against qualifyingmiles.com, our employees, officers, and agents for wrongful death, personal
            injury, property damage, reputational damage and other causes of action known or unknown. You agree to hold
            qualifyingmiles.com, our employees, officers, and agents harmless from and against any losses, liabilities,
            costs, damages, or expenses which result from your participation in the Services, regardless of whether or
            not caused in whole or part by the fault of any of qualifyingmiles.com, our employees, officers, and agents.
        </p>

        <h3>Miscellaneous</h3>
        <p>
            This Agreement is entered into in the State of Colorado and shall be governed by and construed in accordance
            with the laws of the State of Colorado, exclusive of its choice of law rules. Each party to this Agreement
            hereby submits to the exclusive jurisdiction of the state and federal courts sitting in Denver County in the
            State of Colorado for any dispute arising under or in connection with this Agreement, the Website or any
            Website-related services, and waives any jurisdictional, venue, or inconvenient forum objections to such
            courts. In any action to enforce this Agreement, the prevailing party will be entitled to costs and
            attorneys' fees. In the event that any of the provisions of this Agreement shall be held by a court or other
            tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to
            the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and
            enforceable. Failure of any party to insist upon strict compliance with any of the terms and conditions of
            this Agreement shall not be deemed a waiver or relinquishment of any similar right or power at any
            subsequent time.
        </p>

        <p>
            This Agreement constitutes the entire agreement between the parties hereto pertaining to the subject matter
            hereof, and any and all written or oral agreements heretofore existing between the parties hereto are
            expressly canceled. This Agreement is not assignable, transferable, or sub-licensable by you except with
            prior written consent. Any heading, caption, or section title contained in this Agreement is inserted only
            as a matter of convenience and in no way defines or explains any section or provision hereof. Mileage
            Calculator reserves the right in its sole discretion to terminate the use of the Website by a user at any
            time.
        </p>
    </>
);

export default TermsOfService;
