const KnownIssues = () => (
    <>
        <h2>Known Issues</h2>

        <p>This page aims to describe some of the more common issues present in the app.</p>

        <ul>
            <li>
                <b>Calculation</b>
                <ul>
                    <li>Aeromexico flights always earn in the calculator according to the AM earning chart. AM flights plated on 006/DL will actually earn according to fare paid ("as if they were DL flights").</li>
                    <li>Travel to Cuba on partner airlines earns miles when it's not supposed to.</li>
                    <li>Award tickets have limited support.</li>
                    <li>Regional carrier codes are not supported (so don't try to put in <code>KL/9E</code> because you're clever).</li>
                    <li>The only supported intra-Alaska carriers are 7H, 7S, and GV. It's difficult to figure out if there are others, since Delta does not provide a list.</li>
                    <li>Assigning dollars to segments uses a linear prorate based on flight distance. It seems this is generally accurate for Delta and Delta's close partners, based on a trip I took with mixed DL and KL segments where the calculator got within a few dollars. However, I believe the industry standard is to use the proportion of the square root of each segment, and that this will likely apply to some of the partners that are not as close.</li>
                    <li>The calculator will not convert between dollars and euros, so if you put &euro;300 into the Delta calculator it treats it as $300.</li>
                </ul>
            </li>
            <li>
                <b>Lounge Access</b>
                <ul>
                    <li>I was not sure if some "Caribbean" airports like PLS qualified for the lounge exception.</li>
                    <li>Some short-haul itineraries like JFK-MEX in Delta-operated business class may give a false positive for lounge access.</li>
                    <li>Connecting flights to transcontinental itineraries (e.g. JFK-LAX) do not show Sky Club access when it is allowed.</li>
                    <li>Lounge access does not consider if a lounge is accessible in the same terminal or a connected terminal.</li>
                    <li>The Amex Platinum and Amex Centurion cards only afford access to Centurion Lounges and Sky Clubs. If the card or its associated Priority Pass card lets you into another lounge (e.g. the Air France lounge in Boston, the Virgin Clubhouse in JFK during Priority Pass hours, or the Plaza Premium lounge in LGW), the calculator does not show it.</li>
                    <li>The calculator assumes that any DL*/WS flight is also plated on 006/DL for the purposes of lounge access for Amex Platinum/Centurion members. This may lead to a false positive if your ticket is issued on another carrier's ticket stock.</li>
                    <li>The calculator treats the whole itinerary as the highest cabin booked (e.g. <code>STL-DL.Y-ATL-DL.P-CDG</code> is treated as premium economy). This works for GM/PM/DM Sky Club access, but not for SkyTeam business access (where you have to be confirmed in the premium cabin for all segments) or how other carriers do lounge access (where you only receive access before your flight in business).</li>
                    <li>For carriers with multiple levels of lounges (AF, CI, KE, MU), access is only coded for the business lounge. If you're flying <i>La Premi&egrave;re</i> they will escort you there anyway.</li>
                </ul>
            </li>
            <li>
                <b>Interface</b>
                <ul>
                    <li>Some airlines that are in SkyTeam do not have a SkyTeam logo next to their logo on the earning chart page (I tried to find good logos but it was tough for certain carriers).</li>
                    <li>The earning chart interface is difficult to use on mobile (since you can't tell when the chart switches).</li>
                </ul>
            </li>
        </ul>
    </>
);

export default KnownIssues;
