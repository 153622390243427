import FFPInformation from "./interfaces/FFPInformation";
import EarningRates from "./earning-rates-2025.json";

const XPTable = {
    "domestic": {
        "Economy": 2,
        "Premium Economy": 4,
        "Business": 6,
        "First": 10,
        "La Première": 10,
    },
    "medium": {
        "Economy": 5,
        "Premium Economy": 10,
        "Business": 15,
        "First": 25,
        "La Première": 25,
    },
    "long1": {
        "Economy": 8,
        "Premium Economy": 16,
        "Business": 24,
        "First": 40,
        "La Première": 40,
    },
    "long2": {
        "Economy": 10,
        "Premium Economy": 20,
        "Business": 30,
        "First": 50,
        "La Première": 50,
    },
    "long3": {
        "Economy": 12,
        "Premium Economy": 24,
        "Business": 36,
        "First": 60,
        "La Première": 60,
    },
}

export const AFKLEliteBonuses = {
    "silver": 0.5,
    "gold": 0.75,
    "plat": 1,
    "ulti": 1,
    "skip": 1,
    "hipp": 1,
    "club": 1,
}

export const AllianceAirlineCodes = EarningRates.airlines
    .map(airline => [airline.alliance, airline.iata])
    .reduce((a, [key, value]) => ({
        ...a,
        [key || "" as string]: [...(a[key || ""] || []), value || ""]
    }), {} as { [alliance: string]: string[] });

export const RouteTypeOverrides: { [key: string]: "domestic" | "medium" | "long1" | "long2" | "long3" } = {
    'BEY-CDG': 'long1',
    'CAI-CDG': 'long1',
    'BOS-CDG': 'long2',
    'AMS-BOS': 'long2',
    'AMS-YUL': 'long2',
    'CDG-YUL': 'long2',
};

function getRouteType(route: { distance?: number, marketing?: string, operating?: string, upgradedFareClass?: string, paidUpgrade?: string, fareClass?: string, from: string, fromAirport: any, to: string, toAirport: any }) {
    const override = RouteTypeOverrides[[route.from, route.to].sort((a, b) => a.localeCompare(b, 'en')).join('-')];
    if (override) return override;

    if (route.fromAirport?.country === route.toAirport?.country)
        return "domestic";
    if ((route.distance ?? 0) < 2000)
        return "medium";
    if ((route.distance ?? 0) < 3500)
        return "long1";
    if ((route.distance ?? 0) < 5000)
        return "long2";
    return "long3";
}

function calculateXP(
    route: { distance?: number, marketing?: string, operating?: string, upgradedFareClass?: string, paidUpgrade?: string, fareClass?: string, from: string, fromAirport: any, to: string, toAirport: any },
    cabin: string,
) {
    if (!route.marketing || route.marketing === "AF" || route.marketing === "KL")
        return (XPTable[getRouteType(route)] as any)?.[cabin || 'Economy'] || 0;
    
    const operatingCarrier = route.operating || route.marketing || 'AF';
    if (!expandOperatingCarriers(["SkyTeam", "SB"]).includes(operatingCarrier))
        return 0;

    if (route.marketing === "RO" && route.fromAirport?.country === "Romania" && route.toAirport?.country === "Romania")
        return 2 * (XPTable[getRouteType(route)] as any)?.[cabin || 'Economy'] || 0;

    return (XPTable[getRouteType(route)] as any)?.[cabin || 'Economy'] || 0;
}

function expandOperatingCarriers(operatingCarriers: string[]): string[] {
    return operatingCarriers.map(iata => AllianceAirlineCodes[iata] || [iata]).flat();
}

export function calculateEarning(
    route: { distance?: number, marketing?: string, operating?: string, upgradedFareClass?: string, paidUpgrade?: string, fareClass?: string, from: string, fromAirport: any, to: string, toAirport: any },
    ffpInformation: FFPInformation,
): { rdms?: number, xp: number, uxp: number } {
    
    const chartSelector = route.marketing === "SK" && ["domestic", "medium"].includes(getRouteType(route)) ? "short-haul" : "";
    const chart = (EarningRates.ffp.AFKL as any)[route.marketing ?? "AF"]?.charts?.[chartSelector];
    const match = chart?.filter((line: any) => line.class.includes(route.fareClass))[0];

    if (!match)
        return {
            rdms: 0,
            xp: calculateXP(route, 'Economy'),
            uxp: calculateXP(route, 'Economy'),
        };
    
    if (route.operating && route.marketing !== "AF" && route.marketing !== "KL" && !expandOperatingCarriers(match.operatingCarriers).includes(route.operating))
        return {
            rdms: 0,
            xp: 0,
            uxp: 0,
        };
    
    const hasEliteBonus = expandOperatingCarriers(["SkyTeam", "SB"]).includes(route.marketing ?? "");
    const eliteMultiplier = hasEliteBonus ? ((AFKLEliteBonuses as any)[ffpInformation.eliteStatus ?? ""] ?? 0) : 0;

    if (route.marketing && (route.marketing !== "AF" && route.marketing !== "KL")) {
        return {
            rdms: Math.round(Math.max(route.distance ?? 0, 500) * ((match.rdms?.base ?? 0) * (1 + eliteMultiplier) + (match.rdms?.cabin ?? 0))),
            xp: calculateXP(route, match.cabin),
            uxp: 0,
        };
    }

    return {
        rdms: 0,
        xp: calculateXP(route, match.cabin),
        uxp: calculateXP(route, match.cabin),
    };
}
