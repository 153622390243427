import EarningRates2024 from "./earning-rates-2024.json";
import EarningRates2025 from "./earning-rates-2025.json";
import FFPInformation from "./interfaces/FFPInformation";

export const HawaiiAirports = ["HNL", "OGG", "KOA", "LIH", "ITO"];

export const CapeAirDestinations = ["BOS", "SJU", "STT", "STX", "BIL"];

export const AlaskanCarriers = ["7H", "7S", "GV"];

export const EuropeanCountries = [
    'Albania',
    'Andorra',
    'Austria',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Iceland',
    'Ireland',
    'Italy',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Malta',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'North Macedonia',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'Russia',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Turkey',
    'Ukraine',
    'United Kingdom',
    'Vatican City',
];

export const EuropeanRussianAirports = [
    'AAQ',
    'AER',
    'AMV',
    'ARH',
    'ASF',
    'BZK',
    'CEE',
    'CSY',
    'DME',
    'EGO',
    'EIK',
    'ESL',
    'GDZ',
    'GOJ',
    'GRV',
    'GSV',
    'IAR',
    'IGT',
    'IJK',
    'IWA',
    'JOK',
    'KGD',
    'KLF',
    'KMW',
    'KRR',
    'KUF',
    'KVK',
    'KVX',
    'KZN',
    'LED',
    'LNX',
    'LPK',
    'MCX',
    'MMK',
    'MRV',
    'NAL',
    'NBC',
    'NNM',
    'OGZ',
    'OSF',
    'OSW',
    'PEE',
    'PES',
    'PEZ',
    'PKV',
    'REN',
    'ROV',
    'RTW',
    'SCW',
    'SIP',
    'SKX',
    'STW',
    'SVO',
    'TBW',
    'TGK',
    'UCT',
    'UFA',
    'ULV',
    'ULY',
    'URS',
    'USK',
    'UUA',
    'VGD',
    'VKO',
    'VKT',
    'VLI',
    'VOG',
    'VOZ',
    'VRI',
    'VUS',
    'ZIA',
]

export const AllianceAirlineCodes = EarningRates2025.airlines
    .map(airline => [airline.alliance, airline.iata])
    .reduce((a, [key, value]) => ({
        ...a,
        [key || "" as string]: [...(a[key || ""] || []), value || ""]
    }), {} as { [alliance: string]: string[] });

export const DLEliteBonuses: { [status: string]: number } = {
    "": 0,
    "SM": 0,
    "FO": 0.4,
    "GM": 0.6,
    "PM": 0.8,
    "DM": 1.2,
    "360": 1.2,
};

function expandOperatingCarriers(operatingCarriers: string[]): string[] {
    return operatingCarriers.map(iata => AllianceAirlineCodes[iata] || [iata]).flat();
}

function isEuropeanAirport(airport: any): boolean {
    if (airport.country === 'Russia')
        return EuropeanRussianAirports.includes(airport.iata_code);
    return EuropeanCountries.includes(airport.country);
}

function isEuropeanRoute({ fromAirport, toAirport }: any): boolean {
    return isEuropeanAirport(fromAirport) && isEuropeanAirport(toAirport);
};

export function calculateEarning(
    route: { distance?: number, marketing?: string, operating?: string, upgradedFareClass?: string, paidUpgrade?: string, fareClass?: string, from: string, fromAirport: any, to: string, toAirport: any },
    ffpInformation: FFPInformation,
): { rdms?: number, mqms?: number, mqds?: number } {

    const earning = (ffpInformation.travelYear === '2023' ? EarningRates2024.ffp.DL : EarningRates2025.ffp.DL);
    const isQualifying9K = (route.marketing === "9K" && (!route.operating || route.operating === "9K") && (CapeAirDestinations.includes(route.from) || CapeAirDestinations.includes(route.to)));
    const isQualifyingAlaskanCarrier = (AlaskanCarriers.includes(route.marketing ?? "") && (!route.operating || AlaskanCarriers.includes(route.operating ?? "")));

    const eliteBonusRate = DLEliteBonuses[ffpInformation.eliteStatus ?? ""] || 0;

    if (!route.marketing || route.marketing === "DL" || isQualifying9K || isQualifyingAlaskanCarrier)
    {
        if ((!route.marketing || route.marketing === "DL") && route.fareClass === "E")
            return {
                rdms: 0,
                mqms: 0,
                mqds: 0,
            };
        
        const upgradedFareClass = route.paidUpgrade === "true" ? route.upgradedFareClass?.[0] : null;
        
        const cabinMqmMultiplier = (route.marketing && route.marketing !== "DL") || !route.fareClass
            ? 1
            : ["F", "J"].includes(upgradedFareClass || route.fareClass) ? 2
            : ["P", "A", "G", "C", "D", "I", "Z", "O", "R", "Y", "B"].includes(upgradedFareClass || route.fareClass) ? 1.5
            : 1;
        
        return {
            rdms: undefined,
            mqms: route.distance && Math.round(Math.max(500, route.distance) * cabinMqmMultiplier),
            mqds: undefined,
        };
    }

    if (!Object.keys(earning).includes(route.marketing) || !route.distance)
        return {
            rdms: undefined,
            mqms: undefined,
            mqds: undefined,
        };

    const chartSelector = (
        ['AF', 'KL'].includes(route.marketing) && isEuropeanRoute(route)) ? "intra-europe"
        : route.marketing === 'SK' && route.operating === 'DL' ? "delta-operated"
        : "";
    console.log('Using chart', chartSelector);

    const chart = (earning as any)[route.marketing]?.charts?.[chartSelector];
    if (!chart)
        return {
            rdms: undefined,
            mqms: undefined,
            mqds: undefined,
        };
    const match = chart?.filter((line: any) => line.class.includes(route.fareClass))[0];

    if (!match)
        return {
            rdms: 0,
            mqms: 0,
            mqds: 0,
        };
    if (route.operating && !expandOperatingCarriers(match.operatingCarriers).includes(route.operating))
        return {
            rdms: 0,
            mqms: 0,
            mqds: 0,
        };
    
    if (route.marketing === "HA" && (!HawaiiAirports.includes(route.from) || !HawaiiAirports.includes(route.to)))
        return {
            rdms: 0,
            mqms: 0,
            mqds: 0,
        };
    
    const mqmMinimum = ["AF", "AM", "KL", "KE", "LA", "MU"].includes(route.marketing) ? 500 : 250;

    return {
        rdms: Math.ceil((match.rdms.base + match.rdms.cabin) * route.distance + match.rdms.base * (match.rdms.eliteBonus ? eliteBonusRate : 0) * route.distance),
        mqms: match.elite.mqm * route.distance > 0 ? Math.ceil(match.elite.mqm * Math.max(mqmMinimum, route.distance)) : 0,
        mqds: Math.ceil(match.elite.mqd * route.distance),
    };
}
