import React, { useCallback, useState } from "react";
import EarningRates2024 from "../earning-rates-2024.json";
import EarningRates2025 from "../earning-rates-2025.json";
import { Col, Form, Row, Table } from "react-bootstrap";

const EarningCharts = () => {
    const [selectedAirline, setSelectedAirline] = useState<string>();
    const [program, setProgram] = useState<'DL' | 'AFKL'>('DL');
    const [programYear, setProgramYear] = useState<'2024' | '2025'>(new Date().getFullYear() + 1 === 2024 ? '2024' : '2025');

    const EarningRates = programYear === '2024' ? EarningRates2024 : EarningRates2025;

    const AirlineInformation = Object.fromEntries(EarningRates.airlines.map(a => [a.iata, a]));

    const airline = selectedAirline ? ((EarningRates.ffp as any)?.[program] as any)?.[selectedAirline] : null;

    const onProgramChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value) {
            setProgram(e.target.value as any);
            setProgramYear('2025');
        }
    }, []);

    return (
        <div className="earning-charts">
            <h1 className='h2'>Earning Charts</h1>
            <p>These are the airline earning charts used to calculate earnings in this calculator. Click on an airline's logo to see the chart.</p>

            <Row>
                <Col md={6}>
                    <Form.Group className='mb-4'>
                        <Form.Label htmlFor="program">Program</Form.Label>
                        <Form.Select name="program" value={program} onChange={onProgramChange}>
                            <option value="DL">Delta SkyMiles</option>
                            <option value="AFKL">Flying Blue</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group className='mb-4'>
                        <Form.Label htmlFor="ffp">
                            <span className="d-none d-lg-inline">Program</span>
                            {' '}
                            Year
                        </Form.Label>
                        <Form.Select
                            name="ffp-year"
                            value={program === 'DL' ? programYear : '2025'}
                            onChange={(e) => setProgramYear(e.target.value as '2024' | '2025')}
                            disabled={program !== 'DL'}
                        >
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row style={{ userSelect: "none", WebkitUserSelect: "none", MozUserSelect: "-moz-none" }}>
                {Object.keys(EarningRates.ffp.DL).map((iata) => (
                    <Col sm={6} md={4} xl={3} xxl={2} key={iata} className="airline-logo"
                    style={Object.assign({}, { cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20, borderRadius: 10 }, selectedAirline === iata ? { background: 'rgb(222, 226, 230)' } : {})}
                    onClick={() => setSelectedAirline(iata)}>
                        {(AirlineInformation[iata] as any)?.logo
                            ? <img className="mb-4" src={`images/ffp-logos/${(AirlineInformation[iata] as any).logo}`} alt={AirlineInformation[iata].name} style={{ display: 'block', margin: "auto", maxWidth: "100%", maxHeight: "55px" }} draggable={false} />
                            : <h2 className="h3 mb-4" style={{ flex: 1 }} >{AirlineInformation[iata]?.name ?? iata}</h2>
                        }
                    </Col>
                ))}
            </Row>

            {selectedAirline && <hr className="my-4" />}

            <h2 className="h3">{selectedAirline && AirlineInformation[selectedAirline]?.name}</h2>

            {program === 'DL' && airline?.charts && Object.entries(airline?.charts).map(([name, chart]) => (
                <>
                    {name !== undefined && (airline as any).chartNames && <h3 className="h5">{((airline as any).chartNames as any)[name]}</h3>}
                    <Table striped className="mb-5">
                        <thead>
                            <tr>
                                <th className="d-none d-lg-table-cell">Cabin</th>
                                <th>Fare Class</th>
                                {programYear === '2024' && <th>Base <span className="d-none d-lg-inline">Miles</span></th>}
                                {programYear === '2024' && <th>Class <span className="d-none d-lg-inline">Bonus</span></th>}
                                {programYear === '2024' ? (
                                    <th>
                                        <span className="d-none d-lg-inline">Total Miles</span>
                                        <span className="d-lg-none">Total</span>
                                    </th>
                                ) : <th>RDMs</th>}
                                {programYear === '2024' && <th>MQMs</th>}
                                <th>MQDs</th>
                            </tr>
                        </thead>

                        <tbody>
                            {(chart as any).map((row: any) => (
                                <tr>
                                    <td className="d-none d-lg-table-cell">{row.cabin}</td>
                                    <td>{row.class.join(", ")}</td>
                                    {programYear === '2024' && <td style={{ borderLeftWidth: 3, borderLeftStyle: "solid", borderLeftColor: "rgb(222, 226, 230)" }}>{row.rdms.base * 100}%</td>}
                                    {programYear === '2024' && <td>{row.rdms.cabin * 100}%</td>}
                                    <td>{(row.rdms.base + row.rdms.cabin) * 100}%</td>
                                    {programYear === '2024' && <td style={{ borderLeftWidth: 3, borderLeftStyle: "solid", borderLeftColor: "rgb(222, 226, 230)" }}>{row.elite.mqm * 100}%</td>}
                                    <td>{row.elite.mqd * 100}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ))}

            {program === 'AFKL' && airline?.charts && Object.entries(airline?.charts).map(([name, chart]) => (
                <>
                    {name !== undefined && (airline as any).chartNames && <h3 className="h5">{((airline as any).chartNames as any)[name]}</h3>}
                    <Table striped className="mb-5">
                        <thead>
                            <tr>
                                <th className="d-none d-lg-table-cell">Cabin</th>
                                <th>Fare Class</th>
                                {programYear === '2024' && <th>Base <span className="d-none d-lg-inline">Miles</span></th>}
                                {programYear === '2024' && <th>Class <span className="d-none d-lg-inline">Bonus</span></th>}
                                {programYear === '2024' ? (
                                    <th>
                                        <span className="d-none d-lg-inline">Total Miles</span>
                                        <span className="d-lg-none">Total</span>
                                    </th>
                                ) : <th>RDMs</th>}
                                {programYear === '2024' && <th>MQMs</th>}
                            </tr>
                        </thead>

                        <tbody>
                            {(chart as any).map((row: any) => (
                                <tr>
                                    <td className="d-none d-lg-table-cell">{row.cabin}</td>
                                    <td>{row.class.join(", ")}</td>
                                    {programYear === '2024' && <td style={{ borderLeftWidth: 3, borderLeftStyle: "solid", borderLeftColor: "rgb(222, 226, 230)" }}>{row.rdms.base * 100}%</td>}
                                    {programYear === '2024' && <td>{row.rdms.cabin * 100}%</td>}
                                    {(selectedAirline === 'AF' || selectedAirline === 'KL') ? (
                                        <td>By Fare</td>
                                    ) : (
                                        <td>{((row.rdms?.base ?? 0) + (row.rdms?.cabin ?? 0)) * 100}%</td>
                                    )}
                                    {programYear === '2024' && <td style={{ borderLeftWidth: 3, borderLeftStyle: "solid", borderLeftColor: "rgb(222, 226, 230)" }}>{row.elite.mqm * 100}%</td>}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ))}

            <small>{airline?.disclaimers.map((d: string) => <p>{d}</p>)}</small>
        </div>
    );
}

export default EarningCharts;